<script setup lang="ts">
import { ref } from 'vue';


import Segment from "~/components/custom-ui/Segment.vue";
import { Role } from "~/types/role";
import { getMastersRecentList, getTasksRecentList } from "~/services/dictionary-service";
import { onClickOutside } from "@vueuse/core";
import RecentSpecialistCard from "~/components/custom-ui/RecentSpecialistCard.vue";
import RecentTaskCard from "~/components/custom-ui/RecentTaskCard.vue";
import Autoplay from "embla-carousel-autoplay";
import { heads } from "~/constants/heads.js";
import { Carousel } from '@/components/ui/carousel'

definePageMeta({
    layout: "default",
});

useHead(heads.index)

const {t} = useI18n()

const userProfile = useUserProfileStore();
const { parentSpecifications } = useDictionaryStore();
const searchQuery = ref('');
const open = ref(false);

const segmentOptions = ref([
    { value: Role.CUSTOMER, name: 'I\'m looking for a specialist' },
    { value: Role.MASTER, name: 'I\'m a specialist' },
]);

const role = ref(userProfile.Role ? userProfile.Role : Role.CUSTOMER);

const categoryLink = computed(() => {
    return role.value === Role.MASTER ? '/tasks/category-' : '/catalog/category-'
})

const { locales, locale } = useI18n();
const filteredItems = computed(() => {
    if (!searchQuery.value) {
        return parentSpecifications;
    }
    return parentSpecifications.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.value.toLowerCase())
    );
});
const target = ref(null)


const { data: recentSpecialists } = await getMastersRecentList();

const { data: recentTasks } = await getTasksRecentList();

function clear() {
    open.value = false
}

function navigate(item: object) {
    let route = 'category'
    if(!!item.parent){
        route = 'sub-category'
    }
    if (role.value === Role.CUSTOMER) {
        navigateTo(`/catalog/${route}-${item.id}`)
    }
    if (role.value === Role.MASTER) {
        navigateTo(`/tasks/${route}-${item.id}`)
    }
}

onClickOutside(target, clear)

const plugin = Autoplay({
    delay: 2000,
    stopOnMouseEnter: true,
    stopOnInteraction: false,
})
const city = ref(true)


function randomizer<T>(arr: T[]): T[] {
    const shuffled = [...arr];
    for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled.slice(0, 3);
}

const randomElements = randomizer(parentSpecifications);

const direction = computed(() => locale.value === 'ar' ? 'rtl' : 'ltr')
const carouselContainerRef = ref<InstanceType<typeof Carousel> | null>(null)

function resetUiCarousel(){
    carouselContainerRef.value?.carouselApi?.reInit(
        {
            direction: locale.value === 'ar' ? 'rtl' : 'ltr'
        },
    )
    plugin.reset()
}
watch(locale, (newLocale) => {
    nextTick(() => {
        resetUiCarousel()
    })
});
watch(role, (newRole) => {
    userProfile.setRole(newRole)
    nextTick(() => {
        resetUiCarousel()
    })
});

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

</script>


<template>
    <div class="index_page_container">
        <div class="container relative h-[50px]">
            <!-- <div v-if="!userProfile.Role && city">
                <div class="flex items-center gap-4">
                    <p class="mb-0">Your region Dubai?</p>
                    <ui-button class="button__secondary" @click="city = false">Yes</ui-button>
                    <UiButton class="button__secondary" @click="city = false">Select other region</UiButton>
                </div>
            </div> -->
        </div>

        <div class="container">
            <PagesHomePageSearchComponent :role="role">
                <div class="search max-w-[550px] mx-auto">
                    <Segment v-show="!userProfile.id" :items="segmentOptions" :default="Role.CUSTOMER" v-model="role" />
                    <div class="relative w-full">
                        <div class="search__input ">
                            <UiInput @input="open = true" maxLength="20" v-model="searchQuery" id="search"
                                type="text" class="search__control text-md text-[#3A3E59] rtl:pr-5 ltr:pl-5 h-[60px] rounded-full" 
                                :placeholder="$t('Service or specialist ...')" />
                            <div class="search-icon__container rtl:left-2 ltr:right-2">
                                <Icon size="24" class="search-icon__icon" name="ri:search-2-line" />
                            </div>
                        </div>
                        <div v-if="open" ref="target"
                            class="absolute bg-white rounded-3xl mt-1 max-h-[136px] py-1 px-2 search__dropdown">
                            <UiScrollArea class="mx-4 my-2 max-h-[120px]">
                                <div class="py-2 px-2 hover:bg-gray-100 rounded-md" v-for="item in filteredItems"
                                    @click="navigate(item); open = false">
                                    <p class="dc-selector-button text-md text-[#3A3E59] font-medium px-2 cursor-pointer flex items-center text-center"
                                        role="menuitem" tabindex="-1">
                                        {{ item.name }}
                                    </p>
                                </div>
                                <p v-if="!filteredItems.length"
                                    class="dc-selector-button text-md text-[#3A3E59] font-medium px-2 cursor-pointer flex items-center text-center"
                                    role="menuitem" tabindex="-1">
                                    {{ $t('The search returned no results ...') }}
                                </p>
                            </UiScrollArea>
                        </div>
                    </div>
                    <div class="flex justify-center gap-2 w-full">
                        <NuxtLink class="tag tag--light transition-all" v-for="item in randomElements" :to="`${categoryLink}${item.id}`">{{ item.name }}</NuxtLink>
                    </div>
                </div>
            </PagesHomePageSearchComponent>

            <PagesHomePageThingsToExperts class="mt-24" :role="role">
                <h1 class="header !text-center" v-if="role === Role.CUSTOMER">
                    {{ $t("Leave things to the") }}
                    <span class="header--highlight">{{ $t("experts") }}</span>
                </h1>
                <h1 class="header !text-center" v-if="role === Role.MASTER" v-html="t('Browse tasks by category')"></h1>
            </PagesHomePageThingsToExperts>

            <div class="home__offer_workers rounded-[20px] bg-stroke p-8 relative mt-12">
                <div class="offer_text max-w-[345px] mx-auto text-center">
                    <h2 class="font-bold text-3xl" v-html="$t('All purpose worker')"></h2>
                    <p v-if="role === Role.CUSTOMER" class="font-medium text-xl mt-6">{{ $t('View workers that can complete variety of tasks for you!') }}</p>
                    <p v-if="role === Role.MASTER" class="font-medium text-xl mt-6">{{ $t('Ready for any job? Start your career in the UAE! YourService is your first step to success.') }}</p>
                    
                    <NuxtLink v-if="role === Role.CUSTOMER" asChild to="/catalog/category-198">
                        <UiButton class="rounded-full mt-9 bg-accent hover:bg-[#FE9902] text-main">
                            <span  class="font-medium text-sm">{{ $t("Find worker") }}</span>
                            <Icon name="ri:arrow-right-up-line" class="ml-1 mt-0.5" />
                        </UiButton>
                    </NuxtLink>
                    <UiButton v-else-if="role === Role.MASTER" @click.prevent="scrollToTop" class="rounded-full mt-9 bg-accent hover:bg-[#FE9902] text-main">
                        <span v-if="role === Role.MASTER" class="font-medium text-sm">{{ $t("Get a job now") }}</span>
                        <Icon name="ri:arrow-right-up-line" class="ml-1 mt-0.5" />
                    </UiButton>
                </div>
                
                <img src="/home_page/worker_1.png" alt="" class="absolute left-8 bottom-0">
                <img src="/home_page/worker_2.png" alt="" class="absolute right-4 bottom-0">
            </div>

            <div class="recent-items mt-24">
                <h2 v-if="role === Role.MASTER" class="recent__header" v-html="$t('Recent tasks')"></h2>
                <h2 v-if="role === Role.CUSTOMER" class="recent__header" v-html="$t('Recent specialists')"></h2>
                <div class="specialist-list">
                    <UiCarousel
                        :plugins="[plugin]"
                        ref="carouselContainerRef"
                        :opts="{
                            align: 'center',
                            loop: false,
                            direction: direction
                        }">
                        <UiCarouselContent class="-ml-7">
                            <template v-if="role === Role.CUSTOMER">
                                <UiCarouselItem v-for="item in recentSpecialists" :key="item.id" class="basis-1/4 pl-7">
                                    <RecentSpecialistCard v-if="item?.id" :specialist="item" />
                                </UiCarouselItem>
                            </template>
                            <template v-if="role === Role.MASTER">
                                <UiCarouselItem v-for="item in recentTasks" :key="item.id" class="basis-1/4 pl-7">
                                    <RecentTaskCard :task=item />
                                </UiCarouselItem>
                            </template>
                        </UiCarouselContent>
                    </UiCarousel>
                </div>
            </div>

            <PagesHomePageYourServiceTutorials class="mt-24" />

        </div>
    </div>
</template>

<style scoped lang="scss">
.button__secondary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 4px;
    background: #F6F8FA !important;
    border-radius: 10px;
    flex: none;
    order: 1;
    flex-grow: 0;
    color: rgba(82, 88, 102, 1);

}

.search {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;

    &__input {
        position: relative;
        width: 100%;
        font-weight: 500;
        line-height: 45px;
    }

    &__dropdown {
        width: 100%;
        box-shadow: 0 4px 20px rgba(0,0,0,.1);
    }

    &__control {
        border-radius: 28px;
        height: 60px;
        width: 100%;

        &::placeholder {
            line-height: 45px;
            font-weight: normal;
        }
    }
}

.search-icon {
    &__container {
        width: 48px;
        height: 48px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 10%;
        background: #0C3B2E;
    }

    &__icon {
        color: #FFFFFF;
    }

}

.specialist-list {
    overflow-x: hidden;
    --scrollbar-height: 8px;
    --mask-width: 100px;
    mask-image: linear-gradient(to right, transparent, black var(--mask-width), black calc(100% - var(--mask-width)), transparent),
        linear-gradient(black, black);
    mask-size: 100% calc(100% - var(--scrollbar-height)), 100% var(--scrollbar-height);
    mask-position: 0 0, 0 100%;
    mask-repeat: no-repeat, no-repeat;
}

.specialist-list {
    scrollbar-width: thin;
    scrollbar-color: currentColor transparent;
}

/* Webkit / Blink */
.specialist-list::-webkit-scrollbar {
    height: var(--scrollbar-height);
}

.specialist-list::-webkit-scrollbar-thumb {
    background-color: currentColor;
    border-radius: 9999px;
}

.specialist-list::-webkit-scrollbar-track {
    background-color: transparent;
}

.recent-items {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.highlight-text {
    color: #FEB902;
}

.recent__header {
    font-family: Rubik, serif;
    font-size: 38px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0.02em;
    text-align: center;
}

.tag__list {
    display: flex;
    gap: 3px;
}

.tag {
    max-width: calc(33% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
    &--light {
        box-sizing: border-box;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        border: 1px solid #E2E4E9;
        border-radius: 28px;
        color: rgba(255, 255, 255, 1);
        font-family: 'Inter', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;

        &:hover {
            background: rgba(109, 151, 115, 1);
            border-color: rgba(109, 151, 115, 1);
        }
    }
}

.header {
    font-family: Rubik, serif;
    font-size: 38px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #000000;
    font-size: 38px;

    &--highlight {
        color: #FEB902;
    }
}
</style>